.order-id-wrapper {
  padding: 18px 14px 14px;

  .order-label,
  .order-number {
    font-family: 'Roboto', sans-serif;
    opacity: 0.8;
    font-stretch: normal;
    font-style: normal;
    color: #292b2c;
  }

  .order-label {
    margin-right: 15px;
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 1px;
  }

  .order-number {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.63;
    letter-spacing: 0.28px;
  }
}
