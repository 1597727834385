@import 'theme/variables.scss';

.client-update-form {
  margin: 40px 134px 50px;

  .header-wrapper {
    text-align: center;

    .unified-order-modal-header {
      margin-bottom: 70px;
    }
  }

  .description-label {
    padding: 5px 0;
    opacity: 0.8;
    color: #292b2c;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .client-admin-input {
    border: 1px solid rgba(41, 43, 44, 0.2);
    margin: 5px 0;
    max-width: 500px;

    .pasword-masking {
      margin-right: 15px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .change-link {
    padding-left: 12px;
    font-size: 10px;
    text-transform: none;

    &:hover {
      cursor: pointer;
    }
  }

  .password-field {
    flex-direction: row-reverse;

    &[aria-disabled="true"] {
      background-color: #f2f2f2;
    }
  }

  .update-buttons {
    height: 39px;
    margin: 5px 0 5px 15px;
  }

  .cta-wrapper {
    margin-top: 30px;

    ion-button {
      width: 100%;
      min-height: 49px;
      margin: 0 15px 0 0;

      &:last-child {
        margin-right: 0;
        margin-left: 15px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: 0 35px;
    margin: 115px auto 0;

    .cta-wrapper {
      flex-direction: column-reverse;

      ion-button {
        margin-right: 0;
        margin: 10px 0 0 !important;
      }
    }
  }
}
