@import 'theme/variables.scss';

.unified-form-wrapper {
  margin: 70px 134px 30px;
  text-align: center;

  .client-id {
    display: inline-block;
    font-size: 14px;
    padding-left: 15px;
    opacity: 0.8;
    color: #8c8c8c;
  }

  .native-input.sc-ion-input-md {
    height: 100%;
  }

  .unified-modal-label {
    display: inline-block;
    padding: 5px 0;
    opacity: 0.8;
    color: #292b2c;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .delivery-method-wrapper {
    display: flex;
    align-items: center;

    .delivery-mode-wrapper {
      --min-height: 0;
      --inner-padding-end: 0;
      --padding-start: 0;
      --background-hover: transparent;
      margin: 0 25px 0 0;
      border: 0;
    }

    .delivery-mode-checkbox {
      margin: 0 10px 0 0;
      --background-checked: #292b2c;
      --border-color-checked: #292b2c;
    }

    .delivery-mode-text {
      font-size: 13px;
    }
  }

  .unified-modal-input {
    color: #292b2c;

    &.multiline {
      border: solid 1px rgba(41, 43, 44, 0.2);

      textarea {
        height: 80px;
      }
    }
    @mixin base-flag {
      background-size: 30px;
      background-repeat: no-repeat;
      background-position: 8px 8px;
      textarea {
        padding-left: 50px;
      }
    }
    &.language-en {
      @include base-flag;
      background: url('/assets/flags/png/4x3/us.png');
    }
    &.language-fr {
      @include base-flag;
      background: url('/assets/flags/png/4x3/fr.png');
    }
  }

  .store-sections {
    position: relative;
    margin: 15px auto;
    text-align: center;
  }

  .store-sections::before,
  .store-sections::after {
    position: absolute;
    content: "";
    background: #f0f0f0;
    display: block;
    width: 185px;
    height: 2px;
    top: 50%;
  }

  .store-sections::before {
    left: 0;
  }

  .store-sections::after {
    right: 0;
  }

  .item-wrapper, .language-wrapper {
    text-align: left;

    .change-password-link {
      padding-left: 12px;
      font-size: 10px;
      text-transform: none;
    }

    .ion-label span {
      opacity: 0.6;
      font-size: 12px;
      letter-spacing: 0.4px;
      color: #292b2c;
    }

    ion-input {
      border: 1px solid rgba(41, 43, 44, 0.2);
      height: 49px;
    }

    .error {
      color: red;
    }

    .label-optional {
      opacity: 0.6;
      font-size: 12px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: 0.4px;
      color: #292b2c;
    }

    .days-to-hold-order {
      display: flex;
      flex-direction: row;
      text-align: center;

      ion-icon:nth-child(1) {
        order: 1;
        margin-left: 15px;
      }

      ion-icon:nth-child(2) {
        order: 3;
        margin-right: 15px;
      }

      input {
        order: 2;
      }

      ion-icon:nth-child(1),
      ion-icon:nth-child(2) {
        &:hover {
          cursor: pointer;
        }
      }
    }

    .passwords-fields {
      display: flex;
      flex-direction: row-reverse;

      &.disabled {
        background-color: #f2f2f2;
      }
    }

    .pasword-masking {
      margin-right: 15px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .cta-wrapper {
    margin-top: 20px;

    ion-button {
      width: 100%;
      min-height: 49px;
      margin: 0 15px 0 0;

      &:last-child {
        margin: 0 0 0 15px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: 0 35px;
    margin: 115px auto 0;

    .unified-modal-header {
      margin-bottom: 70px;
    }

    .store-sections::before,
    .store-sections::after {
      width: 80px;
    }

    .cta-wrapper {
      flex-direction: column-reverse;

      ion-button {
        margin: 0 0 25px;

        &:last-child {
          margin: 0 0 10px;
        }
      }
    }
  }
}
