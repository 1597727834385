@import 'theme/variables.scss';
.location-form-wrapper {
  .location-form-title {
    margin: 0;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: 0.23px;
    text-align: center;
    color: #232323;
  }

  .location-form-subtitle {
    margin: 0;
    margin-bottom: 32px;
    opacity: 0.8;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: 0.28px;
    text-align: center;
    color: #292b2c;
  }

  ion-button {
    margin: 15px 0 0 0;
    width: 100%;
  }
}
