.customer-order-details {
  // S7 special heading margin
  .customer-order-pickup-later-success {
    .order-message-wrapper {
      .order-message-title {
        margin-bottom: 32px;
      }
    }
  }
}
