.order-contact-store {
  .order-contact-store-contact {
    margin: 0;

    + .order-contact-cancel-pickup {
      margin-top: 28px;
    }
  }

  .order-contact-cancel-pickup {
    display: inline-block;
  }
}
