@import 'theme/variables.scss';

@mixin base-languages-dropdown {
  width: 120px;
  padding-right: 5px;
  margin: 10px 15px 0 0;
  border: 1px solid rgba(41, 43, 44, 0.2);
  height: 49px;
  padding-left: 60px;

  @include media-breakpoint-down(sm) {
    margin: 18px 15px 0 0;
  }
}

@mixin languages-dropdown-flag($flag) {
  @include base-languages-dropdown;
  background-image: url('/assets/flags/png/4x3/' + $flag);
  background-size: 40px;
  background-repeat: no-repeat;
  background-position: 5px 8px;
}

.header-languages-dropdown {
  @include base-languages-dropdown;
  width: 73px;
  padding-left: 10px;
}

.languages-dropdown {
  @include base-languages-dropdown;
  width: 100%;
  padding-left: 10px;
}

.select-alert {
  button {
    text-transform: none;
  }
}

.languages-dropdown-en {
  @include languages-dropdown-flag('us.png');
}
.languages-dropdown-fr {
  @include languages-dropdown-flag('fr.png');
}


.header-languages-dropdown-en {
  @include languages-dropdown-flag('us.png');
}
.header-languages-dropdown-fr {
  @include languages-dropdown-flag('fr.png');
}
