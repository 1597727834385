.cp-404 {
  margin: 80px 0 100px;

  .cp-404-icon {
    margin: 0 0 12px;
    font-size: 44px;
    color: #767676 !important;
    --ion-color-base: #767676 !important;
  }

  .cp-404-title {
    margin: 0 0 10px;
  }

  .cp-404-subtitle {
    margin: 0;
  }
}
