@import '../../theme/variables.scss';

.orders-page {
  .initial-loader {
    margin-top: 125px;
  }

  .order-lane {
    flex: 0 0 20%;
    width: 20%;
    max-width: 20%;

    @include media-breakpoint-down(sm) {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
    }
  }

  @include media-breakpoint-down(sm) {
    .add-order-div {
      width: 100%;
      margin: 0 auto 64px;
    }

    .search-button {
      display: none;
    }
  }

  .order-container {
    width: 80%;
    min-width: 1200px;
    margin: auto;
    margin-top: 23px;

    @media screen and (max-width: 1400px) {
      width: 100%;
      min-width: auto;
      margin-top: 0;
      padding: 32px 35px;
    }
  }

  .filter-section {
    margin-top: 1px;
    display: flex;
    justify-content: space-between;
    padding: 0 5px;

    @include media-breakpoint-down(sm) {
      padding: 0 0 12px;

      .search-field {
        width: 100%;
      }
    }

    .date-picker-field {
      margin-right: 25px;

      .react-date-picker__wrapper {
        border: solid 1px rgba(41, 43, 44, 0.2);

        .react-date-picker__inputGroup {
          margin-left: 10px;
          order: 2;
          pointer-events: none;
        }

        .react-date-picker__clear-button {
          order: 3;
        }

        .react-date-picker__calendar-button {
          order: 1;
        }
      }

      .react-calendar__tile--active {
        background-color: #292b2c;
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 25px;
        z-index: 22;

        .react-date-picker__wrapper {
          padding: 10px 0;
        }
      }
    }

    .search-order {
      width: 431px;
      height: 48px;
      font-size: 14px;
      line-height: 1.86;
      letter-spacing: 0.28px;
      color: #292b2c;

      @include media-breakpoint-down(sm) {
        width: 100%;
        margin-right: 0;
      }
    }

    .search-icon {
      margin-right: 22px;
      color: rgba(41, 43, 44, 0.2);
    }

    .add-order-button {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      border: solid 1px #292b2c;
      margin: 0;
      height: 48px;
      width: 250px;
      font-size: 14px;
    }

    .date-filter-button {
      @include media-breakpoint-down(sm) {
        width: 100%;
        margin-bottom: 25px;
      }

      border: solid 1px rgba(41, 43, 44, 0.2);
      width: auto;
      margin-right: 15px;
    }

    .search-button {
      border: solid 1px #292b2c;
      margin: 0;
      height: 48px;
      width: 157px;
      color: black;
      font-size: 16px;
    }
  }

  .orders-tab {
    margin-top: 16px;
    padding: 0;

    @include media-breakpoint-down(sm) {
      margin-top: 0;

      .reset-margin-x-sm {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .orders {
      .order-column {
        @include media-breakpoint-down(sm) {
          width: 89%;
          margin: 0 auto 12px;
        }

        width: calc(25% - 15px);
        min-width: 263px;
        margin-right: 15px;

        &:last-child {
          @include media-breakpoint-down(sm) {
            margin-right: auto;
            width: 89%;
          }

          margin-right: 0;
          width: 25%;
        }
      }
    }
  }
}

.add-address-modal-container {
  .modal-wrapper {
    @include media-breakpoint-down(sm) {
      height: 100%;
    }

    width: 770px;
    height: 574px;

    .add-address-modal {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      width: 770px;

      ion-icon.modal-close-icon {
        position: absolute;
        top: 30px;
        right: 30px;
        height: 32px;
        width: 32px;
        --ionicon-stroke-width: 16px;
        font-size: 16px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.picker-wrapper {
  top: 0;

  .picker-opt-selected {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }

  @include media-breakpoint-down(sm) {
    top: unset;
  }
}
