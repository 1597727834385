.order-details-delivery-instructions-wrapper {
  .order-details-delivery-title {
    margin-top: 0;
    font-size: 18px;
    color: #292b2c;
  }

  .order-details-delivery-instructions {
    font-size: 12px;
  }
}
