.pickup-location-wrapper {
  background: #f7f7f7;
  padding: 32px 35px;

  .pickup-location-title {
    margin: 0 0 20px;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: center;
    opacity: 0.8;
    color: #292b2c;
  }

  .pickup-location-icon {
    font-size: 32px;
  }

  .pickup-store-id {
    margin: 8px 0;
    opacity: 0.6;
    line-height: 1.5;
  }

  .pickup-address {
    margin-top: 0;
    margin-bottom: 25px;
    font-size: 12px;
    line-height: 1.5;
  }

  .pickup-external-links {
    display: flex;
    padding: 0 40px;
    align-items: center;
    justify-content: space-between;
  }

  .default-link {
    display: flex;
    align-items: center;
  }

  .external-link-icon {
    margin-left: 6px;
    font-size: 12px;
  }

  .directions-icon {
    margin-left: 4px;
    font-size: 18px;
  }
}
