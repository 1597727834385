@import 'theme/variables.scss';

.order-list-card {
  @include media-breakpoint-down(sm) {
    max-width: 100%;
    padding: 0 20px;
  }
  background-color: #f7f7f7;
  border-radius: 0;
  border: solid 1px rgba(41, 43, 44, 0.1);
  margin: 0;
  padding: 0 10px;

  .card-accordian {
    opacity: 0.6;
    font-size: 16px;
    color: #292b2c;
  }

  ion-card-title {
    margin-top: 5px;
    font-size: 12px;
    font-weight: bold;
    opacity: 0.8;
    color: #292b2c;
    text-transform: uppercase;

    @include media-breakpoint-down(sm) {
      margin: 0;
      font-size: 10px;
      letter-spacing: 0.8px;
      color: #111111;
    }
  }

  .card-header {
    @include media-breakpoint-down(sm) {
      padding: 15px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    padding: 16px 0;
  }

  .column-card-content {
    @include media-breakpoint-down(sm) {
      height: unset;
      overflow: unset;
    }
    padding: 7px 0 0 0;
    height: 600px;
    overflow: auto;
  }
}

.hightlight {
  -webkit-animation: flickerAnimation 1s infinite;
  -moz-animation: flickerAnimation 1s infinite;
  -o-animation: flickerAnimation 1s infinite;
  animation: flickerAnimation 1s infinite;
  ion-card-title {
    color: red;
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
