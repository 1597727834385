@import '../../theme/variables.scss';

.order-details-wrapper {
  margin: 40px 134px 15px;

  .header-note {
    margin: 0px 65px 20px;
    text-align: center;
  }

  .sub-header {
    font-size: 14px;
    text-align: center;
    margin-bottom: 35px;
  }

  .order-number-label,
  .description-label {
    display: block;
    margin-bottom: 20px;
    opacity: 0.8;
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 1px;
    color: #292b2c;
    text-transform: uppercase;
  }

  .order-number {
    margin: 0 0 28px;
    font-size: 21px;
    font-weight: bold;
    letter-spacing: 0.23px;
    margin-bottom: 24px;
    color: #292b2c;
  }

  .description-label {
    margin-bottom: 8px;
    margin-top: 25px;
  }

  .comment-label {
    display: inline-block;
    margin-top: 20px;
  }

  .edit-comment-icon {
    margin-left: 25px;
    font-size: 12px;
  }

  .order-note {
    border: solid 1px rgba(41, 43, 44, 0.2);

    textarea {
      height: 160px;
    }
  }

  .note-text {
    border: solid 1px #f0f0f0;
    padding: 15px;
  }

  .description {
    font-size: 14px;
    opacity: 0.8;
    line-height: 1.63;
    letter-spacing: 0.28px;
    margin: 0;
  }

  .cta-wrapper {
    @include media-breakpoint-down(sm) {
      flex-direction: column-reverse;
    }

    margin-top: 15px;

    ion-button {
      @include media-breakpoint-down(sm) {
        margin-right: 0;
        margin-left: 0;
      }

      width: 100%;
      font-size: 16px;
      margin: 0;
      margin-right: 15px;
      min-height: 49px;

      &:last-child {
        @include media-breakpoint-down(sm) {
          margin-right: 0;
          margin-left: 0;
          margin-bottom: 10px;
        }

        margin-right: 0;
        margin-left: 15px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: 32px 35px;
    margin: 50px auto 0;

    .header-note {
      margin: 0 0 64px;
    }

    .order-number-label {
      margin-bottom: 12px;
    }

    .cta-wrapper {
      margin-top: 64px;
      flex-direction: column-reverse;
    }
  }
}
