.default-link {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.28px;
  text-decoration: none;
  color: #0275d8;

  &:hover {
    text-decoration: underline;
  }
}
