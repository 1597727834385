/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #00804a;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #e0e0e0;
  --ion-color-light-tint: #ffffff;
}

html {
  &,
  &.ios,
  &.md {
    --ion-font-family: 'Roboto', -apple-system, BlinkMacSystemFont,
      'Helvetica Neue', sans-serif;
  }
}

.oe-container {
  max-width: 1303px;
  margin: 0 auto;
}

ion-header {
  background-color: #f7f7f7;
}

ion-toolbar {
  --background: transparent;
}

.w-100 {
  width: 100% !important;
}

.d-flex {
  display: flex !important;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

.justify-space-between {
  justify-content: space-between !important;
}

.justify-center {
  justify-content: center !important;
}

.reset-padding {
  padding: 0 !important;
}

.reset-margin {
  margin: 0 !important;
}

.center {
  text-align: center !important;
}

.left {
  text-align: left !important;
}

h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.27px;
  color: #292b2c;
}

label {
  opacity: 0.8;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #292b2c;
}

p {
  opacity: 0.8;
  font-size: 10px;
  letter-spacing: 0.2px;
  color: #292b2c;
}

.default-p,
.p-strong {
  opacity: 0.8;
  font-size: 14px;
  line-height: 1.63;
  letter-spacing: 0.28px;
  color: #292b2c;
}

.p-link {
  margin: 0;
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #0275d8;
  opacity: 1;

  ion-icon {
    font-size: 14px;
  }
}

ion-button,
button {
  text-transform: uppercase;
  --border-radius: 0px;

  &.button-primary {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.46px;
    text-align: center;
    color: #ffffff;
    box-shadow: none;
    --box-shadow: none;

    &.not-ionic {
      min-height: 49px;
      padding: 0 16px;
      background-color: #292b2c;
    }

    &.disabled {
      opacity: 0.6;
    }
  }

  &.button-secondary {
    font-family: 'Roboto', sans-serif;
    height: 49px;
    border: solid 1px #e9e9e9;
    box-shadow: none;
    --box-shadow: none;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.46px;
    text-align: center;
    color: #292b2c;
  }
}

ion-item {
  border: solid 1px rgba(41, 43, 44, 0.2);
}

.text-feedback {
  display: block;
  font-size: 14px;
}

.error-feedback {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 12px;
  color: red;
}
