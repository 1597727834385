.customer-order-form-wrapper {
  .customer-order-form-label {
    display: block;
    margin-bottom: 12px;
    opacity: 0.8;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.8px;
    color: #111111;
    text-transform: uppercase;
  }

  .customer-order-form-textarea {
    width: 100%;
    min-height: 139px;
    margin-bottom: 15px;
    padding: 12px 15px;
    background-color: #fff;
    border: solid 1px #e9e9e9;
    opacity: 0.8;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: 0.28px;
    color: #292b2c;
  }

  .customer-order-form-submit {
    width: 100%;
    margin: 0 0 12px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.46px;
    text-align: center;
    min-height: 49px;
  }
}
