@import 'theme/variables.scss';

.client-admin-page {
  .oe-container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .logo-wrapper {
    margin: 90px auto 67px;
    align-items: center;
    text-align: center;
    justify-content: space-between;
  }

  @include media-breakpoint-down(md) {
    .logo-wrapper {
      margin: 90px auto 25px;
    }
  }
}
