@import 'theme/variables.scss';

.client-admin-profile-wrapper {
  width: 60%;
  margin: auto;

  .description-label {
    padding: 5px 0;
    opacity: 0.8;
    color: #292b2c;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .description-text {
    display: block;
    font-size: 18px;
    color: #292b2c;
    opacity: 0.8;
    margin-bottom: 20px;
  }

  .client-logo {
    max-width: 150px;
    margin-top: 10px;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.update-client-modal-container {
  .modal-wrapper {
    width: 770px;
    height: 574px;

    .update-client-modal {
      width: 770px;

      ion-icon.modal-close-icon {
        position: absolute;
        top: 30px;
        right: 30px;
        height: 32px;
        width: 32px;
        --ionicon-stroke-width: 16px;
        font-size: 16px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      height: 100%;

      .update-client-modal {
        width: 100%;
      }
    }
  }
}
