@import 'theme/variables.scss';

.cp-header {
  .content-wrapper {
    @include media-breakpoint-down(sm) {
      justify-content: center;
    }

    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    justify-content: space-between;
    vertical-align: middle;

    .logo-wrapper {
      @include media-breakpoint-down(sm) {
        margin: 19px;
      }

      margin: 10px;
    }

    .store-id-wrapper,
    .manager-logout {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .store-id-wrapper {
      @include media-breakpoint-down(sm) {
        display: none;
      }

      margin-left: 12px;

      .store-id-label {
        margin: 0;
        opacity: 0.8;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
        color: #292b2c;
      }

      .delivery-method-text {
        font-weight: normal;
        .delivery-method-label {
          text-transform: capitalize;
        }
      }
    }

    .client-logo {
      max-width: 219px;
    }

    .manager-logout {
      margin: 0 5px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
