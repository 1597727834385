@import '../../theme/variables.scss';

.order-card {
  margin: 0 0 12px 0;
  box-shadow: none;
  border: solid 1px rgba(41, 43, 44, 0.1);
  border-radius: 0;

  .edit-order-icon {
    margin-right: 10px;
  }

  .notification-status-icon {
    font-size: 18px;
    vertical-align: middle;
  }

  .edit-order-icon,
  .delete-icon,
  .details-icon {
    display: inline-block;
    font-size: 14px;

    &:hover {
      cursor: pointer;
    }
  }

  .delete-icon {
    margin-left: 10px;
  }

  ion-card-header {
    background-color: #f1f1f1;
    padding: 13px 10px;
  }

  .cp-card-content {
    top: 10px;
    padding: 0 10px 12px;
    text-align: center;

    ion-label {
      margin: 0 0 10px;
      display: block;
      font-size: 12px;
      text-align: left;

      ion-badge {
        padding: 2px 6px;
        vertical-align: middle;
        border-radius: 8px;
        font-size: 12px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: normal;
        color: #ffffff;
        text-transform: uppercase;
      }

      .status-label {
        text-transform: uppercase;
      }

      .status-value {
        font-weight: normal;
      }

      .notification-information {
        width: 90%;

        .notification-logo {
          margin-right: 8px;
          vertical-align: middle;
        }
      }
    }

    .notification-details {
      display: flex;
      justify-content: space-between;
    }

    .order-language-text {
      text-transform: uppercase;
    }
  }

  ion-card-subtitle {
    font-size: 12px;
    font-weight: bold;
    opacity: 0.8;
    color: #292b2c !important;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  ion-card-title {
    font-size: 21px;
    font-weight: bold;
    opacity: 1;
    color: #292b2c !important;
    text-transform: uppercase;
    letter-spacing: 0.23px;

    &:hover {
      cursor: pointer;
    }
  }

  .out-for-curbside-button {
    margin: 7px auto 12px;
    width: 100%;
    height: 40px;
    font-size: 12px;
    background-color: #f7f7f7;
  }
}

.order-detail-modal-container {
  .modal-wrapper {
    width: 770px;
    height: 613px;

    @include media-breakpoint-down(sm) {
      height: 100%;
    }

    .order-details-modal {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      width: 770px;

      ion-icon.modal-close-icon {
        position: absolute;
        top: 30px;
        right: 30px;
        height: 32px;
        width: 32px;
        --ionicon-stroke-width: 16px;
        font-size: 16px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.edit-modal {
  .modal-wrapper {
    width: 770px;
    height: 574px;
  }

  @include media-breakpoint-down(sm) {
    .modal-wrapper {
      height: 100%;
    }
  }
}
