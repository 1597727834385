@import 'theme/variables.scss';

.login-page {
  .logo-wrapper {
    margin: 70px auto 120px;
    text-align: center;
    justify-content: center;

    @include media-breakpoint-down(sm) {
      margin: 90px auto 0;
    }
  }
}
