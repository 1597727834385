@import 'theme/variables.scss';

.store-details {
  margin-bottom: 100px;

  .available-info-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .available-info-text {
      opacity: 0.8;
      font-size: 18px;
      line-height: 1;
      letter-spacing: 0.3px;
      color: #292b2c;
      margin: 0 20px 0 0;
    }

    .purchase-link {
      font-size: 10px;
      letter-spacing: 0.13px;
      text-align: center;
      color: #0275d8;
    }

    .add-store-button {
      min-width: 155px;
      min-height: 48px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.4px;
    }
  }

  .stores-container {
    margin: 63px -15px 0 -15px;

    .store-card-wrapper {
      padding: 15px;

      .store-card {
        border: solid 1px #e8e8e8;
        background-color: #ffffff;
        padding: 20px 16px 27px 16px;
        font-family: Roboto;

        .label-text {
          font-size: 12px;
          opacity: 0.8;
          font-weight: bold;
          letter-spacing: 1px;
          color: #292b2c;
          text-transform: uppercase;
        }

        .store-id-value-text {
          opacity: 0.6;
          font-size: 16px;
          line-height: 0.94;
          letter-spacing: 0.32px;
          color: #292b2c;
          display: inline-block;
          margin: 12px 0 16px 0;
        }

        .value-text {
          opacity: 0.6;
          font-size: 12px;
          line-height: 1.25;
          letter-spacing: 0.24px;
          color: #292b2c;
          display: inline-block;
          margin: 6px 0 16px 0;
        }

        .available-methods-container {
          margin-top: 6px;
        }

        .delivery-method-icons {
          font-size: 24px;
          margin-right: 8px;
        }

        .delivery-method-text {
          margin-right: 28px;
          text-transform: capitalize;
        }
        .display-inline {
          display: inline;
          margin-left: 11px;
        }

        .button-align {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 13px 0 0 0;

          .edit-buttons {
            font-size: 12px;
            font-weight: 500;
            width: 50%;
            letter-spacing: 0.34px;
            text-align: center;
            color: #ffffff;
            margin: 0;
          }

          .deactivate-link {
            width: 50%;
            text-align: center;
          }
        }
      }

      .inactive-store {
        background-color: #f0f0f0;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .available-info-wrapper {
      display: unset;

      .available-info-text {
        display: block;
        margin: 0 0 10px 0;
      }

      .add-store-button {
        width: 100%;
        margin-top: 15px;
      }
    }

    .stores-container {
      margin: 40px -15px 0 -15px;
    }
  }
}

.update-form-modal-container {
  .modal-wrapper {
    width: 770px;
    height: 710px;

    .update-form-modal {
      width: 770px;

      ion-icon.modal-close-icon {
        position: absolute;
        top: 30px;
        right: 30px;
        height: 32px;
        width: 32px;
        --ionicon-stroke-width: 16px;
        font-size: 16px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .modal-wrapper {
      height: 100%;

      .update-form-modal {
        width: 100%;
      }
    }
  }
}

.confirm-modal-container {
  .modal-wrapper {
    width: 410px;
    height: 353px;

    .confirm-modal {
      text-align: center;

      .confirm-heading {
        margin: 40px 0 50px 0;
      }

      .confirm-text {
        opacity: 0.8;
        font-size: 16px;
        display: block;
        line-height: 1.5;
        letter-spacing: 0.27px;
        text-align: center;
        color: #292b2c;
        margin: 0 0 35px 0;
      }

      .confirm-button {
        max-width: 237px;
        display: block;
        margin: 15px auto;
        border: solid 1px #e9e9e9;
      }

      ion-icon.modal-close-icon {
        position: absolute;
        top: 30px;
        right: 30px;
        height: 30px;
        width: 30px;
        --ionicon-stroke-width: 16px;
        font-size: 16px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .modal-wrapper {
      width: 100%;
    }
  }
}
