@import 'theme/variables.scss';

.popup-card {
  min-width: 360px;
  padding: 16px 20px 20px;
  margin-bottom: 12px;
  border-radius: 4px;
  background-color: #292b2c;

  .card-paragraph {
    color: white;
    font-family: Roboto;
    margin: 0;
  }
  .order-id {
    font-size: 12px;
    line-height: 1.5;
    font-weight: 500;
    letter-spacing: 0.2px;
  }

  .supporting-text {
    opacity: 1;
    font-size: 16px;
    margin: 12px 0 1px;
    line-height: 1.31;
    letter-spacing: 0.27px;
  }

  .details-text {
    font-size: 10px;
    line-height: 1.5;
    letter-spacing: 0.17px;
  }
  .mobile-help-text {
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.2px;
    background-color: #292b2c;
    opacity: 1;
    color: white;

    a {
      color: white;
      text-decoration: underline;
      margin-left: 10px;
      font-size: 12px;
    }
  }

  .popup-card-mobile-close {
    font-size: 18px;
  }

  .view-order-popup-link {
    font-size: 12px;
    letter-spacing: 0.15px;
    text-decoration: underline;
    text-align: center;
    color: #f7f7f7;
  }

  .view-order-popup-close-link {
    font-size: 0;
  }

  .view-order-popup-close-icon {
    font-size: 18px;
  }

  @include media-breakpoint-down(sm) {
    min-width: auto;
    padding: 12px 10px 13px 20px;

    .supporting-text {
      max-width: 136px;
      margin: 12px 8px;
    }
  }
}
