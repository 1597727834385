.cp-footer-wrapper {
  min-height: 130px;
  padding-top: 40px;
  padding-bottom: 42px;

  .copyright-text {
    opacity: 0.6;
    font-size: 10px;
    line-height: 1.5;
    letter-spacing: 0.2px;
    color: #292b2c;
  }

  .term-text {
    font-size: 10px;
    letter-spacing: 0.13px;
    color: #0275d8;
  }

  .link-divisor {
    display: inline-block;
    margin: 0 4px;
  }

  ion-img {
    width: 36px;
    display: inline-block;
  }
}
