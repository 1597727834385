.upload-field {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;

  &.disabled {
    + .upload-label {
      opacity: 0.5;
    }
  }
}

.upload-label {
  display: block;
  width: 100%;
  padding: 12px;
  opacity: 1;
  background-color: #292b2c;
  cursor: pointer;

  .upload-content {
    display: block;
    padding: 4px 0;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.4px;
    color: #fff;
    opacity: 1;
  }

  &.is-loading {
    padding: 8px 8px 9px;
    opacity: 0.8;
    pointer-events: none;
  }

  &.is-selected {
    padding: 3px 10px;
    border: solid 1px #e9e9e9;
    background-color: #f2f2f2;

    .upload-icon-attach {
      margin-left: 0;
      margin-right: 4px;
      font-size: 32px;
    }

    .upload-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 1.5;
      font-size: 12px;
      color: #292b2c;
    }
  }
}

.upload-content-text {
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.upload-icon {
  margin-left: 8px;
  vertical-align: middle;
  font-size: 16px;
}

.upload-remove-link {
  color: #292b2c;
}

.upload-remove-icon {
  font-size: 20px;
}
