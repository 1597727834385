@import 'theme/variables.scss';

.logo-wrapper {
  .logo-thumbnail {
    width: auto;
    height: auto;
    max-width: 100%;

    .client-logo {
      max-width: 219px;
    }
  }

  .tab-and-logout-wrapper {
    width: 40%;

    @include media-breakpoint-down(md) {
      width: 50%;
    }
  }
}
