@import 'theme/variables.scss';

.login-form-wrapper {
  margin: 0 auto 100px auto;
  width: 100%;
  max-width: 500px;

  .login-form-title {
    margin: 0 0 32px;
  }

  .form-field-wrapper {
    margin-bottom: 16px;
    margin-top: 10px;
  }

  .login-form-fieldset {
    margin: 0;
    padding: 0;
    border: none;
  }

  .form-field-label {
    display: block;
    margin: 0 0 7px;
    opacity: 0.8;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #292b2c;
    text-transform: uppercase;
  }

  .change-password-link {
    padding-left: 12px;
    font-size: 10px;
    text-transform: none;
  }

  .form-field-select {
    min-height: 49px;
    padding-left: 8px;
    padding-right: 12px;
    font-size: 16px;
    border: solid 1px rgba(41, 43, 44, 0.2);
  }

  .form-field-input {
    width: 100%;
    padding: 10px 8px;
    min-height: 49px;
    font-size: 16px;
    background-color: #fff;
    border: solid 1px rgba(41, 43, 44, 0.2);
    border-radius: 0;

    &:placeholder-shown {
      font-size: 12px;
      font-style: italic;
    }
  }

  .submit-button {
    width: 100%;
    margin-top: 10px;

    &[disabled] {
      opacity: 0.5;
    }
  }

  @include media-breakpoint-down(sm) {
    // Applying on .login-form-wrapper
    margin: 0;
    width: 100%;
    padding: 35px;
  }
}

.login-form-select.form-field-select-content {
  .popover-content {
    width: 500px;
    border-radius: 0;
  }

  .popover-arrow {
    display: none;
  }

  ion-list {
    padding: 0;
  }

  ion-item {
    border: 0;
  }

  @include media-breakpoint-down(sm) {
    .popover-content {
      left: 45px !important;
      width: calc(100% - 90px) !important;
    }
  }
}
