@import 'theme/variables.scss';

.tabs-wrapper {
  width: 100%;
  height: 56px;

  div {
    position: relative !important;

    ion-tab-bar {
      border: none;

      .tab-button {
        color: #292b2c;
        font-family: inherit;
        font-size: 14px;
        letter-spacing: 1px;
        text-align: center;
        background-color: #ffffff;
        border-bottom: 4px solid #f2f2f2;

        &:hover .tab-names {
          color: #292b2c;
        }

        .tab-names {
          text-transform: uppercase;
          white-space: nowrap;
          max-width: 350px;
        }
      }
    }

    .tab-selected {
      font-weight: 500;
      border-bottom: 4px solid #292b2c !important;
    }
  }

  @include media-breakpoint-down(md) {
    margin-right: 24px;
    height: auto;

    .navigation-title {
      color: #292b2c;
      font-size: 18px;
      font-weight: 500;
    }

    .dropdown-mobile {
      width: 100%;
      min-height: 49px;
      padding-left: 8px;
      padding-right: 12px;
      font-size: 16px;
      border: solid 1px rgba(41, 43, 44, 0.2);
      margin-bottom: 25px;
    }
  }
}

.dropdown-mobile-content {
  .popover-content {
    width: 500px;
    border-radius: 0;
  }

  .popover-arrow {
    display: none;
  }

  ion-list {
    padding: 0;
  }

  ion-item {
    border: 0;
  }

  @include media-breakpoint-down(sm) {
    .popover-content {
      left: 10px !important;
      max-width: calc(100% - 20px);
    }
  }
}
