@import 'theme/variables.scss';

.reset-password-wrapper {
  margin: 0 auto 100px auto;
  width: 100%;
  max-width: 500px;

  .reset-password-title {
    margin: 0 0 32px;
  }

  .reset-password-field {
    min-height: 49px;
    padding-left: 8px;
    padding-right: 12px;
    font-size: 16px;
    border: solid 1px rgba(41, 43, 44, 0.2);
    margin-bottom: 20px;
  }

  .reset-password-button {
    min-height: 49px;
    width: 100%;
    margin-top: 15px;
  }

  .password-match-error {
    color: red;
    font-size: 10px;
  }

  @include media-breakpoint-down(sm) {
    padding: 35px;
  }
}
