@import 'theme/variables.scss';

.unified-order-form-wrapper {
  margin: 40px 134px 50px;

  .native-input.sc-ion-input-md {
    height: 100%;
  }

  .unified-order-modal-header {
    text-align: center;
  }

  .item-wrapper {
    text-align: left;
    margin-top: 20px;

    ion-label {
      opacity: 0.8;
      color: #292b2c;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1px;
      padding: 5px 0;
      display: block;
    }

    .ion-label span {
      opacity: 0.6;
      font-size: 12px;
      letter-spacing: 0.4px;
      color: #292b2c;
    }

    ion-input {
      border: 1px solid rgba(41, 43, 44, 0.2);
      height: 49px;
    }

    .customer-order-form-textarea {
      border: 1px solid rgba(41, 43, 44, 0.2);
      width: 100%;
    }

    .order-id {
      font-size: 24px;
      font-weight: bold;
    }

    .error {
      color: red;
    }
  }

  .communication-helper-text {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #292b2c;

    .addition-text {
      font-weight: normal;
      display: inline;
      text-transform: none;
      letter-spacing: 0.4px;
    }
  }

  .communication-radio-wrapper {
    display: block;
    padding: 25px 0 0;

    .comunication-radio-button {
      margin-right: 10px;
      --color-checked: black !important;
    }

    .comunication-radio-label {
      margin-right: 30px;
      vertical-align: top;
    }
  }

  .cta-wrapper {
    margin-top: 30px;

    ion-button {
      width: 100%;
      min-height: 49px;
      margin: 0 15px 0 0;

      &:last-child {
        margin-right: 0;
        margin-left: 15px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: 0 35px;
    margin: 115px auto 0;

    .unified-order-modal-header {
      margin-bottom: 70px;
      text-align: center;
    }

    .cta-wrapper {
      flex-direction: column-reverse;

      ion-button {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }

  .uppercase {
    text-transform: uppercase;
  }
}

@include media-breakpoint-down(sm) {
  .unified-order-form-wrapper {
    width: 100%;
    padding: 0 35px;
    margin: 115px auto 0;

    .unified-order-modal-header {
      margin-bottom: 70px;
    }

    .cta-wrapper {
      flex-direction: column-reverse;

      ion-button {
        margin-right: 0;
        margin-left: 0;

        &:last-child {
          margin-left: 0;
          margin-bottom: 10px;
        }

        margin-right: 0;
        margin-left: 15px;
      }
    }
  }
}
