.order-details-cancel-pickup-modal {
  padding-top: 32px;

  .modal-close-wrapper {
    margin-bottom: 55px;
    padding-right: 23px;
    text-align: right;
  }

  .modal-close-icon {
    font-size: 32px;
  }

  .cancel-pickup-title {
    margin: 0 0 32px;
  }

  .cancel-pickup-message {
    margin-bottom: 45px;
  }

  .cancel-pickup-confirm {
    margin: 0 0 12px;
  }
}
