.order-message-wrapper {
  .order-message-title {
    margin: 0;
  }

  .order-message-subtitle {
    margin: 12px 0 0;
  }

  .order-message-description {
    margin: 10px 0 0;
  }

  .order-message-icon {
    margin: 5px 0 25px;
    font-size: 44px;

    &.ion-color-success {
      color: #5cb85c !important;
      --ion-color-base: #5cb85c !important;
    }

    &.ion-color-warning {
      color: #f0ad4e;
    }
  }
}
