@import 'theme/variables.scss';

.client-admin-board {
  margin-bottom: 100px;
  text-align: center;
  .title {
    margin-bottom: 88px;
    font-family: 'Roboto', sans-serif;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
  }
  .upload-card-wrapper {
    display: flex;
    align-items: stretch;
    justify-content: center;
  }
  .upload-card {
    position: relative;
    display: inline-block;
    max-width: 370px;
    width: 100%;
    min-height: 227px;
    margin-bottom: 30px;
    margin-left: 30px;
    padding: 64px 66px 60px;
    border: solid 1px #e9e9e9;
    background-color: #f7f7f7;
    &:first-of-type {
      margin-left: 0;
    }
  }
  .upload-card-title {
    margin-bottom: 24px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.23px;
    color: #292b2c;
    opacity: 1;
  }
  .help-fab {
    position: absolute;
    top: -28px;
    right: -28px;
  }
  .help-list {
    position: relative;
    left: -305px;
    width: 300px;
    padding: 0;
    box-shadow: 0 0 10px 0px #ccc;
    text-align: left;
  }
  .help-list-item {
    padding: 0;
    --padding-start: 10px;
    border: 1px solid transparent;
    border-bottom-color: rgba(41, 43, 44, 0.2);
  }
  .help-list-numeral {
    display: flex;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    background-color: #585858;
    font-size: 14px;
    color: #fff;
  }
  .help-list-text {
    margin: 15px 0 15px 10px;
    white-space: normal;
    font-size: 14px;
  }
  .upload-submit {
    margin: 12px 0;
  }
  .checkbox-wrapper {
    border: 0;
    background: transparent;
    --background: var(
      --ion-item-background,
      var(--ion-background-color, transparent)
    );
    opacity: 0.65;
    .checkbox-label {
      margin: 0;
      white-space: normal;
      opacity: 0.9;
      line-height: 12px;
      font-size: 12px;
      font-style: italic;
    }
    .checkbox-input {
      margin: 0 10px 0 -15px;
      opacity: 0.8;
    }
  }
  .download-sample-link {
    display: inline-block;
    margin-top: 12px;
  }
  @include media-breakpoint-down(md) {
    .upload-card-wrapper {
      flex-wrap: wrap;
    }
    .help-list {
      left: -283px;
    }
    .upload-card {
      padding-left: 44px;
      padding-right: 44px;
      &,
      &:first-of-type {
        margin: 0 15px 30px;
      }
    }
  }
}
